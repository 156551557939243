<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    max-width="550"
  >
    <v-form>
      <v-card>
        <v-toolbar dark color="primary" class="elevation-0 px-3">
          <span class="headline">Agregar una nueva razón de solicitud</span>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="12" class="pb-0">
                <form-group
                  name="nombre de la razón de la solicitud"
                  :validator="$v.requestReason.name"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Razón de la solicitud"
                    placeholder="Ingresa el nombre de la razón de la solicitud"
                    required
                    outlined
                    v-model="requestReason.name"
                  />
                </form-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col col="12" sm="12" class="pb-0">
                <form-group
                  name="clasificación de la solicitud"
                  :validator="$v.requestReason.request_clasification_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Clasificación de solicitud"
                    placeholder="Selecciona una clasificación de la solicitud"
                    :items="requestClasifications"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    v-model="requestReason.request_clasification_id"
                  ></v-select>
                </form-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end pt-0">
          <v-btn text outlined class="mr-1" @click="closeModal">Cerrar</v-btn>
          <v-btn color="primary" depressed :loading="isLoading" @click="submit"
            >Agregar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import requestReasonRepository from "@/repositories/requestReasonRepository";

export default {
  name: "CreateRequestReasonModal",

  data() {
    return {
      isLoading: false,
      dialog: false,
      requestReason: {},
    };
  },
  props: {
    requestClasifications: {
      type: Array,
    },
  },
  methods: {
    create() {
      this.isLoading = true;
      requestReasonRepository
        .createRequestReason(this.requestReason)
        .then(({ data }) => {
          // Showing alert with response data
          this.sweetAlertResponse(data);
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.$emit("reload-data");
            this.closeModal();
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.fireToast({
          icon: "error",
          title: "Asegurate de llenar el formulario correctamente",
        });
        return;
      }
      this.create();
    },

    toggleModal() {
      this.dialog = !this.dialog;
    },

    closeModal() {
      this.toggleModal();
      this.$v.$reset();
      this.requestReason = {};
    },
  },
  validations: {
    requestReason: {
      name: {
        required,
        maxLength: maxLength(255),
      },
      request_clasification_id: {
        required,
      },
    },
  },
};
</script>
