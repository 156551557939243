import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllRequestClasifications = () => {
  return ApiService.get("request-clasifications");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getRequestClasification = (id) => {
  return ApiService.get("request-clasifications", id);
};

/**
 * POST request to create a new request clasification
 * @param payload
 * @returns {*}
 */
export const createRequestClasification = (payload) => {
  return ApiService.post("request-clasifications", payload);
};

/**
 * PUT request to update a specific request clasification
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateRequestClasification = (id, payload) => {
  return ApiService.update("request-clasifications", id, payload);
};

/**
 * DELETE request to delete the specified request clasification
 * @param id
 * @returns {*}
 */
export const deleteRequestClasification = (id) => {
  return ApiService.delete(`request-clasifications/${id}`);
};

/**
 * GET request to change status of the specified request clasification
 * @param id
 * @returns {*}
 */
export const changeStatusRequestClasification = (id) => {
  return ApiService.get("request-clasifications/status", id);
};

export default {
  getAllRequestClasifications,
  getRequestClasification,
  createRequestClasification,
  updateRequestClasification,
  deleteRequestClasification,
  changeStatusRequestClasification,
};
