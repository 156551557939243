var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_vm._m(0),_c('div',{staticClass:"card-toolbar"},[(_vm.existInArray('Agregar', _vm.currentPageActions))?_c('v-btn',{attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.showMdlCreate}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-white mr-1"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Home/Book-open.svg"}})],1),_vm._v(" Crear razón ")]):_vm._e()],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-9 col-xl-8"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-4 my-2 my-md-0"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","prepend-icon":"","prepend-inner-icon":"mdi-magnify","placeholder":"Buscar..."},model:{value:(_vm.requestReasons.search),callback:function ($$v) {_vm.$set(_vm.requestReasons, "search", $$v)},expression:"requestReasons.search"}})],1),_c('div',{staticClass:"col-md-4 my-2 my-md-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.requestClasifications.isLoading
                        ? 'Cargando clasificaciones de solicitud'
                        : 'Clasificación de solicitud',"loading":_vm.requestClasifications.isLoading,"disabled":!_vm.requestClasifications.data.length,"outlined":"","dense":"","hide-details":"","clearable":"","multiple":"","items":_vm.requestClasifications.data,"item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.requestReasons.filters.academic_level_id.length - 1)+" seleccionados) ")]):_vm._e()]}}]),model:{value:(_vm.requestReasons.filters.academic_level_id),callback:function ($$v) {_vm.$set(_vm.requestReasons.filters, "academic_level_id", $$v)},expression:"requestReasons.filters.academic_level_id"}})],1)])])])])]),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.requestReasons.headers,"items":_vm.filteredData,"search":_vm.requestReasons.search,"items-per-page":10,"loading":_vm.requestReasons.isLoading},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(_vm.existInArray('Editar', _vm.currentPageActions))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"amber"},on:{"click":function($event){_vm.selectRequestReason(item);
                      _vm.showMdlUpdate();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]:_vm._e(),(_vm.existInArray('Eliminar', _vm.currentPageActions))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":"red"},on:{"click":function($event){_vm.selectRequestReason(item);
                      _vm.showMdlDelete();}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]:_vm._e(),(_vm.existInArray('Activar/Desactivar', _vm.currentPageActions))?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"color":item.status == 1
                        ? 'cyan darken-3'
                        : 'blue-grey darken-1'},on:{"click":function($event){_vm.selectRequestReason(item);
                      _vm.showMdlChangeStatus();}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.status == 1 ? "mdi-eye-outline" : "mdi-eye-off-outline")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.status == 1 ? "Desactivar" : "Activar")+" ")])])]:_vm._e()]}}],null,true)})],1)],1)]),_c('CreateRequestReasonModal',{ref:"mdlCreate",attrs:{"requestClasifications":_vm.requestClasifications.data},on:{"reload-data":_vm.loadRequestReasons}}),_c('UpdateRequestReasonModal',{ref:"mdlUpdate",attrs:{"requestReason":_vm.selectedRequestReason,"requestClasifications":_vm.requestClasifications.data},on:{"reload-data":_vm.loadRequestReasons,"clean-slection":_vm.unselectRequestReason}}),_c('DeleteBaseModal',{ref:"mdlDelete",on:{"delete":_vm.deleteRequestReason},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("¿Desea eliminar este grado?")]},proxy:true},{key:"subject",fn:function(){return [_vm._v(" el grado "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.selectedRequestReason.name))])]},proxy:true}])}),_c('ChangeStatusBaseModal',{ref:"mdlChangeStatus",attrs:{"selected":_vm.selectedRequestReason},on:{"change-status":_vm.changeStatusRequestReason},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" ¿Deseas cambiar el estado de este grado? ")]},proxy:true},{key:"subject",fn:function(){return [_vm._v(" el grado "),_c('span',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.selectedRequestReason.name))])]},proxy:true}])})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-title"},[_c('h3',{staticClass:"card-label"},[_vm._v(" Gestión de las razones de solicitud "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Aqui se podra visualizar, crear, actualizar, eliminar y cambiar el estado de las razones de solicitud.")])])])
}]

export { render, staticRenderFns }